import File from '@/classes/model/File';
import City from '@/classes/model/City';

export default class User {
    public accountNonExpired: boolean = false;
    public accountNonLocked: boolean = false;
    public authorities: Array<string> = [];
    public createdAt: string = '';
    public credentialsNonExpired: boolean = false;
    public enabled: boolean = false;
    public id: number = 0;
    public updatedAt: string = '';
    public username: string = '';
    public birthdate: string|null = null;
    public state: string|null = null;
    public zipcode: string|null = null;
    public profilImage: File|null = null;
    public readme: string|null = null;
    public city: City|null = null;
    constructor() {
        this.id = 0;
        this.username = '';
    }
    public getId(): number {
        return this.id;
    }
    public getUsername() {
        return this.username;
    }
}

/*
"user": {
    "username": "tekki",
    "enabled": true,
    "accountNonLocked": true,
    "authorities": [
        {
            "authority": "role_user"
        }
    ],
    "credentialsNonExpired": true,
    "accountNonExpired": true,
    "id": 4,
    "createdAt": "2022-06-09T10:59:55.273+00:00",
    "updatedAt": "2022-06-09T10:59:55.273+00:00"
}
*/
