import { createApp } from 'vue'
import App from './App.vue'


import { IonicVue } from '@ionic/vue';

import { createPinia } from "pinia";
import { createPersistedStatePlugin } from 'pinia-plugin-persistedstate-2'
const pinia = createPinia()
const installPersistedStatePlugin = createPersistedStatePlugin()
pinia.use((context) => installPersistedStatePlugin(context))

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';

const app = createApp(App);
app.use(IonicVue);
app.use(pinia);

import {useConfig} from '@/stores/config';
const config = useConfig();


config.noimage = "/img/noprofilpicture.png";

config.baseurl = window.location.host;
if(config.baseurl==='192.168.200.5:8100'||config.baseurl==='localhost:8100'&&true) {
    config.mode = 'dev';
    config.api_baseurl = 'http://192.168.200.5:8080';
    config.api_version = 'v1';
    config.ws_baseurl = 'ws://192.168.200.5:8080/websocket'
} else {
    config.mode = 'pro';
    config.api_baseurl = 'https://api.zally.app';
    config.api_version = 'v1';
    config.ws_baseurl = 'wss://api.zally.app/websocket';
}


// Import router after Pinia has been added.
import router from './router';
app.use(router);
  
router.isReady().then(() => {
    app.mount('#app');
});