import Sort from "./Sort";

export default class Page<T> {
    content: T[] = new Array<T>();
    pageable: Pageable = new Pageable();
    last: boolean = false;
    totalPages: number = 0;
    totalElements: number = 0;
    size: number = 0;
    sort: Sort = new Sort();
    first: boolean = false;
    numberOfElements: number = 0;
    empty: boolean = true;
}

class Pageable {
	sort: Sort = new Sort();
	offset: number = 0;
	pageNumber: number = 0;
	pageSize: number = 0;
	unpaged: boolean = false;
	paged: boolean = true;
}

/*
{
	"content": T[],
	"pageable": {
		"sort": {
			"empty": false,
			"sorted": true,
			"unsorted": false
		},
		"offset": 20,
		"pageNumber": 1,
		"pageSize": 20,
		"unpaged": false,
		"paged": true
	},
	"last": true,
	"totalPages": 2,
	"totalElements": 26,
	"size": 20,
	"number": 1,
	"sort": {
		"empty": false,
		"sorted": true,
		"unsorted": false
	},
	"first": false,
	"numberOfElements": 6,
	"empty": false
}
*/
