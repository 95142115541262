
export default class Image {
    public id: number;
    public filename: string;
    public base64: string;

    constructor() {
        this.id = 0,
        this.filename = '',
        this.base64 = ''

    }
}