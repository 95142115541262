
class Level {
    public static OFF = 0;
    public static LOG = 1;
    public static FATAL = 2;
    public static ERROR = 3;
    public static WARN = 4;
    public static INFO = 5;
    public static DEBUG = 6;
    public static TIME = 7; 
    public static TRACE = 8;
    public static ALL = 9;
}

class Logger {

    public OFF: Level = Level.OFF;
    public LOG: Level = Level.LOG;
    public FATAL: Level = Level.FATAL;
    public ERROR: Level = Level.ERROR;
    public WARN: Level = Level.WARN;
    public INFO: Level = Level.INFO;
    public DEBUG: Level = Level.DEBUG;
    public TIME: Level = Level.TIME;
    public TRACE: Level = Level.TRACE;
    public ALL: Level = Level.ALL;

    private level: Level = Level.ALL;

    private isInitialised: boolean;
    private hasChanged: boolean;

    public constructor() {

        this.isInitialised = false;
        this.hasChanged = false;
        this.init();
    }

    public init() {
        if(this.isInitialised && !this.hasChanged) {
            return;
        }

        Object.defineProperty(this, "clear", {get: function () {
                return this.level != this.OFF
                    ? console.clear.bind(console) 
                    : function(){ /***/ };
            },
            configurable: true
        });

        Object.defineProperty(this, "log", {get: function () {
                return this.level >= this.LOG
                    ? console.log.bind(console, '[LOG]')
                    : function(){ /***/ };
            },
            configurable: true
        });

        Object.defineProperty(this, "fatal", {get: function () {
                return this.level >= this.FATAL
                    ? console.error.bind(console, '[FATAL]')
                    : function(){ /***/ };
            },
            configurable: true
        });

        Object.defineProperty(this, "error", {get: function () {
                return this.level >= this.ERROR
                    ? console.error.bind(console, '[ERROR]')
                    : function(){ /***/ };
            },
            configurable: true
        });

        Object.defineProperty(this, "warn", {get: function () {
            return this.level >= this.WARN ? console.warn.bind(console, '[WARN]') : function(){ /***/ };},
            configurable: true
        });

        Object.defineProperty(this, "info", {get: function () {
            return this.level >= this.INFO ? console.info.bind(console, '[INFO]') : function(){ /***/ };},
            configurable: true
        });

        Object.defineProperty(this, "debug", {get: function () {
            return this.level >= this.DEBUG ? console.debug.bind(console, '[DEBUG]') : function(){ /***/ };},
            configurable: true
        });
        
        Object.defineProperty(this, "todo", {get: function () {
            return this.level >= this.LOG ? console.debug.bind(console, '%c[TODO] 🤓 \n','background: #FF0000; color: #0000FF') : function(){ /***/ };},
            configurable: true
        });
        
        Object.defineProperty(this, "time", {get: function () {
            return this.level >= this.TIME ? console.time.bind(console) : function(){ /***/ };},
            configurable: true
        });

        Object.defineProperty(this, "timeLog", {get: function () {
            return this.level >= this.TIME ? console.timeLog.bind(console) : function(){ /***/ };},
            configurable: true
        });

        Object.defineProperty(this, "timeEnd", {get: function () {
            return this.level >= this.TIME ? console.timeEnd.bind(console) : function(){ /***/ };},
            configurable: true
        });

        Object.defineProperty(this, "trace", {get: function () {
            return this.level >= this.TRACE ? console.trace.bind(console, '[TRACE]') : function(){ /***/ };},
            configurable: true
        });  

        this.isInitialised = true;
        this.hasChanged = false;
    }
    

    public setLevel(level: Level) {
        this.level = level;
        this.hasChanged = true;
        this.init();
    }

    public setLevelAll() {
        this.level = this.ALL;
        this.hasChanged = true;
        this.init();
    }

    public getLevel(): Level {
        return this.level;
    }


    public log(message?: any, ...optionalParams: any[]) {
        if(this.level >= this.LOG) {
            console.log(message, optionalParams);
        }
    }

    public fatal(...data: any[]) {
        if(this.level >= this.FATAL) {
            console.error(data)
        }
    }

    public error(...data: any[]) {
        if(this.level >= this.ERROR) {
            console.error(data)
        }
    }

    public warn(message?: any, ...optionalParams: any[]) {
        if(this.level >= this.WARN) {
            console.warn(message, optionalParams);
        }
    }

    public info(message?: any, ...optionalParams: any[]) {
        if(this.level >= this.INFO) {
            console.info(message, optionalParams);
        }
    }

    public debug(message?: any, ...optionalParams: any[]) {
        if(this.level >= this.DEBUG) {
            console.debug(message, optionalParams);
        }
    }  

    public todo(message?: string, ...optionalParams: any[]) {
        if(this.level >= this.DEBUG) {
            const css = 'background: #FF0000; color: #0000FF';
            optionalParams.length!=0 ? console.debug('%c[TODO] '+message, css, optionalParams) : console.debug('%c[TODO] '+message, css);
        }
    }

    public time(label?: string | undefined) {
        if(this.level >= this.TIME) {
            console.time(label)
        }
    }

    public timeEnd(label?: string | undefined) {
        if(this.level >= this.TIME) {
            console.timeEnd(label)
        }
    }

    public timeLog(label?: string | undefined, ...data: any[]) {
        if(this.level >= this.TIME) {
            console.timeLog(label, data)
        }
    }

    public trace(message?: any, ...optionalParams: any[]) {
        if(this.level >= this.TRACE) {
            console.trace(message, optionalParams);      
        }
    }

    public clear() {
        if(this.level >= this.TIME) {
            console.clear()
        }
    }

}
export default new Logger();
