import { defineStore } from "pinia";

export const useConfig = defineStore('config', {
    state: () => {
        return {
            mode: '',
            host: '',
            baseurl: '',
            api_baseurl: '',
            api_version: '',
            ws_baseurl: '',
            noimage: '',
        }
    }
})
