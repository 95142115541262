
import { defineStore } from "pinia";

import User from "@/classes/model/User";
import Server from '@/classes/model/Server';
import Channel from '@/classes/model/Channel';
import Page from '@/classes/model/Page';
import type Message from '@/classes/model/Message';
import Menu from "@/classes/model/Menu";
import Auth from "@/classes/model/Auth";
import Image from '@/classes/model/Image';

export const useStore = defineStore('store', {
    state: () => {
        return {
            token: '',
            language: 'en_us',
            messageBubbles: false,
            auth: new Auth(),
            user: new User(),
            servers: Array<Server>(),
            server: new Server(),
            channels: new Array<Channel>(),
            channel: new Channel(),
            users: new Array<User>(),
            lastPage: new Page<Message>(),
            pages: new Array<Page<Message>>(),
            oldestPage: new Page<Message>(),
            messages: new Array<Message>(),
            image: new Image(),
            images: new Array<Image>(),
            menu: new Menu(),
            notification: Array<{id: number, info: boolean}>()
        }
    },
    actions: {
        setUser(user: User) {
            this.user = user;
        }
    }
})
