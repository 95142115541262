import { createRouter, createWebHistory } from '@ionic/vue-router';
import { useStore } from '../stores/store';
import Cookie from '@/classes/lang/Cookie';
import Logger from '@/classes/lang/Logger';

declare module 'vue-router' {
    interface RouteMeta {
        // must be declared by every route
        requiresAuth: boolean,
        desktop: string,
        mobile: string
    }
}

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes: [
        {
            path: "/",
            name: "home",
            component: () => import("../views/messenger.vue"),
            meta: {
                requiresAuth: false,
                desktop: 'messenger',
                mobile: 'm_messenger'
            }
        },
        {
            path: '/login',
            name: "login",
            component: () => import('../views/login.vue'),
            meta: {
                requiresAuth: false,
                desktop: 'login',
                mobile: 'login'
            }
        },
        {
            path: '/register',
            name: "register",
            component: () => import('../views/register.vue'),
            meta: {
                requiresAuth: false,
                desktop: 'register',
                mobile: 'register'
            }
        },
        {
            path: '/needvalidation',
            name: "needvalidation",
            component: () => import('../views/needvalidation.vue'),
            meta: {
                requiresAuth: false,
                desktop: 'needvalidation',
                mobile: 'needvalidation'
            }
        },
        {
            path: '/validate',
            name: "validate",
            component: () => import('../views/validate.vue'),
            meta: {
                requiresAuth: false,
                desktop: 'validate',
                mobile: 'validate'
            }
        },
        {
            path: '/logout',
            name: "logout",
            component: () => import('../views/logout.vue'),
            meta: {
                requiresAuth: true,
                desktop: 'logout',
                mobile: 'logout'
            }
        },



        {
            path: '/messenger',
            name: "messenger",
            component: () => import('../views/messenger.vue'),
            meta: {
                requiresAuth: true,
                desktop: 'messenger',
                mobile: 'm_messenger'
            }
        },

        {
            path: '/m/messenger',
            name: "m_messenger",
            component: () => import('../views/m/messenger.vue'),
            meta: {
                requiresAuth: true,
                desktop: 'messenger',
                mobile: 'm_messenger'
            }
        },
        {
            path: '/m/people',
            name: "m_people",
            component: () => import('../views/m/people.vue'),
            meta: {
                requiresAuth: true,
                desktop: 'messenger',
                mobile: 'm_people'
            }
        },
        {
            path: '/m/server',
            name: "m_server",
            component: () => import('../views/m/server.vue'),
            meta: {
                requiresAuth: true,
                desktop: 'messenger',
                mobile: 'm_server'
            }
        },
        {
            path: '/m/menu',
            name: "m_menu",
            component: () => import('../views/m/menu.vue'),
            meta: {
                requiresAuth: true,
                desktop: 'messenger',
                mobile: 'm_menu'
            }
        },        



        {
            path: '/profil/:id',
            name: "profil",
            component: () => import('../views/profil.vue'),
            props: true,
            meta: {
                requiresAuth: true,
                desktop: 'profil',
                mobile: 'm_profil'
            }
        },
        {
            path: '/m/profil/:id',
            name: "m_profil",
            component: () => import('../views/m/profil.vue'),
            props: true,
            meta: {
                requiresAuth: true,
                desktop: 'profil',
                mobile: 'm_profil'
            }
        },

        {
            path: '/settings',
            name: "settings",
            component: () => import('../views/settings.vue'),
            meta: {
                requiresAuth: true,
                desktop: 'settings',
                mobile: 'm_settings'
            }
        },

        {
            path: '/m/settings',
            name: "m_settings",
            component: () => import('../views/m/settings.vue'),
            meta: {
                requiresAuth: true,
                desktop: 'settings',
                mobile: 'm_settings'
            }
        },



        {
            path: '/test',
            name: "test",
            component: () => import('../views/test.vue'),
            meta: {
                requiresAuth: false,
                desktop: 'test',
                mobile: 'test'
            }
        },
        {
            path: '/server/new',
            name: "servernew",
            component: () => import('../views/servernew.vue'),
            meta: {
                requiresAuth: true,
                desktop: 'servernew',
                mobile: 'servernew'
            }
        },


        {
            path: '/notfound',
            name: 'notfound',
            component: () => import("../views/not.vue"),
            meta: {
                requiresAuth: false,
                desktop: 'notfound',
                mobile: 'notfound'
            }
        },        
        {
            path: '/:pathMatch(.*)*',
            name: 'not',
            component: () => import("../views/not.vue"),
            meta: {
                requiresAuth: false,
                desktop: 'notfound',
                mobile: 'notfound'
            }
        },
    ],
});

router.beforeEach((to, from) => {

    // Use from.
    if(typeof(from)==='string') {
        // Uff.
    }

    // May  take cookie
    const store = useStore();
    const cookie_token = Cookie.get('app_token');
    if(cookie_token !== '') {
        store.token = cookie_token;
    }

    // Mobile or desktop?
    let mobile: boolean = true;
    const app = document.getElementById('app');
    if (app!==null) {
        if (app.clientWidth <= 780) {
            Logger.log('Small Screen!');
            mobile = true;
        } else {
            Logger.log('Desktop Screen!');
            mobile = false;
        }            
    } else {
        Logger.log('Element \'app\' is not here. :o');
        mobile = true;
    }
    
    // Is a login requred and is there a user?
    if (to.meta.requiresAuth && store.token === '') {
        return {
            name: 'login',
        }
    }


    if (mobile === true && to.name !== to.meta.mobile) {
        Logger.log(to);
        return {
            name: to.meta.mobile,
            params: to.params
        }
    } 

    if (mobile === false && to.name !== to.meta.desktop) {
        return {
            name: to.meta.desktop,
            params: to.params
        }
    }
 
});

export default router;
