import type Channel from './Channel';
import User from './User';
export default class Server {
    public id: number;
    public name: string;
    public description: string;
    public users: User[];
    public channels: Channel[];
    public admin: User;
    public createdAt: string;
    public updatedAt: string;
    constructor() {
        this.id = 0;
        this.name = '';
        this.description = '';
        this.users = new Array<User>();
        this.channels = new Array<Channel>();
        this.admin = new User();
        this.createdAt = '';
        this.updatedAt = '';
    }
    /*
    public getId(): number {
        return this.id;
    }nel
    public getName(): string {
        return this.name;
    }
    public getDescription(): string {
        return this.description;
    }
    public getUsers(): User[] {
        return this.users;
    }
    public getChannels(): any[] {
        return this.channels;
    }
    public getAdmin(): User {
        return this.admin;
    }
    public getCreatedAt(): string {
        return this.createdAt;
    }
    public getUpdatedAt(): string {
        return this.updatedAt;
    }
    */
}

/*
[
	{
		"name": "zally.app",
		"description": "Center of zally!",
		"users": [
			{
				"username": "tekki",
				"enabled": true,
				"accountNonLocked": true,
				"authorities": [
					{
						"authority": "role_user"
					}
				],
				"credentialsNonExpired": true,
				"accountNonExpired": true,
				"id": 4,
				"createdAt": "2022-06-09T17:43:24.366+00:00",
				"updatedAt": "2022-06-09T17:43:24.366+00:00"
			}
		],
		"channels": [],
		"id": 1,
		"admin": {
			"username": "tekki",
			"enabled": true,
			"accountNonLocked": true,
			"authorities": [
				{
					"authority": "role_user"
				}
			],
			"credentialsNonExpired": true,
			"accountNonExpired": true,
			"id": 4,
			"createdAt": "2022-06-09T17:43:24.366+00:00",
			"updatedAt": "2022-06-09T17:43:24.366+00:00"
		},
		"createdAt": "2022-06-09T17:43:44.478+00:00",
		"updatedAt": "2022-06-09T17:43:44.478+00:00"
	}
]
*/
