class Cookie {

   /**
    * Helperfunction to set a cookie
    * @param cname key
    * @param cvalue value
    * @param expiryDate exdiry date as timestamp
    *  expiring period in seconds
    */
    public set(cname: string, cvalue: string, expiryDate: number): void {
        const d = new Date();
        //d.setTime(d.getTime() + (secondsUntilExpiration*1000));
        d.setTime(expiryDate*1000);
        const expires = "expires="+ d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }

    /**
     * Helperfunction to get the value of a specific cookie with name
     * @param cname key
     * @returns 
     */
    public get(cname: string): string {
        const name = cname + "=";
        const decodedCookie = decodeURIComponent(document.cookie);
        const ca = decodedCookie.split(';');
        for(let i = 0; i <ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    public delete(cname: string): void {
        const exdays = 7;
        const d = new Date();
        //document.cookie = "username=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        d.setTime(d.getTime() - (exdays*24*60*60*1000)); // Since this is before now browser should delete the token.
        const expires = "expires="+ d.toUTCString();

        document.cookie = cname + "=;" + expires + ";path=/"; // If not deleted, the value is empty.
    }
}
export default new Cookie;