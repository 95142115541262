
export default class Channel {
    public id: number;
    public name: string;
    public description: string;
    constructor() {
        this.id = 0,
        this.name = '',
        this.description = ''
    }
}